import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';
import './assets/styles/base.css';

import GA4React from 'ga-4-react'; // Google Analytics
import TagManager from 'react-gtm-module'; // Google Tag Manager

import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import Signup from './routes/Signup/Signup';

import Home from './routes/Home/Home';
import MyNumbers from './routes/MyNumbers/MyNumbers';
import MyPrizes from './routes/MyPrizes/MyPrizes';
import Results from './routes/Results/Results';

// Admin pages
import HomeAdmin from './routes/admin/Pages/Home';
import LoginAdmin from './routes/admin/Login/Login';
import RegisteredAdmin from './routes/admin/Pages/Registered';
import VouchersAdmin from './routes/admin/Pages/Vouchers';
import ResultsAdmin from './routes/admin/Pages/Results';
import DrawsAdmin from './routes/admin/Pages/Draws';
import ActivityAdmin from './routes/admin/Pages/Activity';
import RegisterAdmin from './routes/admin/Pages/Register';
import AcompanharAdmin from './routes/admin/Pages/Acompanhar';
import EmailChangeRequests from './routes/admin/Pages/EmailChangeRequests';
import AdminLayout from './layout/admin/AdminLayout';
import Tos from './routes/Tos/Tos';
import Faq from './routes/Faq/Faq';
import UserLayout from './layout/UserLayout/UserLayout';

// Initialize GA4
const ga4react = new GA4React(process.env.REACT_APP_GA_TRACKING_ID);
ga4react.initialize().then().catch();

// Initialize GTM
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_TRACKING_ID,
};
TagManager.initialize(tagManagerArgs);


// All routes and pages loaded
function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path='/'  element={<UserLayout />}>
            <Route path="" element={<Home />} />
            <Route path="signup" element={<Signup />} />
            <Route path="resultados" element={<Results />} />
            <Route path="numeros" element={<MyNumbers />} />
            <Route path="premios" element={<MyPrizes />} />
            <Route path="regulamento" element={<Tos />} />
            <Route path="faq" element={<Faq />} />
          </Route>
          <Route path="/admin" element={<LoginAdmin />} />
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="home" element={<HomeAdmin />} />
            <Route path="cadastrados" element={<RegisteredAdmin />} />
            <Route path="numeros" element={<VouchersAdmin />} />
            <Route path="resultados" element={<ResultsAdmin />} />
            <Route path="sorteios" element={<DrawsAdmin />} />
            <Route path="atividade" element={<ActivityAdmin />} />
            <Route path="adicionar" element={<RegisterAdmin />} />
            <Route path="acompanhar" element={<AcompanharAdmin />} />
            <Route path="mudanca-email" element={<EmailChangeRequests />} />
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
