import React, {useCallback, useContext, useState } from "react";

import useSetPageTitle from "../../hooks/useSetPageTitle";

import './styles.css';

import UserContext from "../../contexts/UserContext";
import email from '../../assets/solar_mais_premios/email.svg';
import phone from '../../assets/solar_mais_premios/phone.svg';

function Faq() {
    const { currentPromo } = useContext(UserContext);
    useSetPageTitle(`${currentPromo?.label} |  FAQ`);

    const [state, setState] = useState(Array(19).fill(0).reduce((obj, _, i) => ({...obj, [i+1]:''}), {}));
    console.log(state);
    const toggle = useCallback((id) => {
        setState((v) => ({...v, [id]: v[id] === 'open'? '' : 'open'}));
    }, []);

    return (
        <div className="faq-page-container page-container">
            <h1 className="title text-yellow">FAQ</h1>
            <div className="faq">
                <div className={`faq-item ${state[1]}`} onClick={()=>toggle(1)}>
                    <h5 className="faq-item_title">1 - QUAL É O PERÍODO PARA PARTICIPAR DA PROMOÇÃO?</h5>
                    <div className="faq-item_content clearfix">A participação nesta Promoção será válida em todo território Solar, tendo início no dia X de novembro de 2024 e término em X de dezembro de 2024 (horário de Brasília).</div>
                </div>
                <div className={`faq-item ${state[2]}`} onClick={()=>toggle(2)}>
                    <h5 className="faq-item_title">2 - QUAIS SÃO OS PRODUTOS PARTICIPANTES DA PROMOÇÃO?</h5>
                    <div className="faq-item_content clearfix">Os produtos participantes da presente promoção “Solar+ Prêmios”, são todos os produtos do portfólio Solar Coca-Cola.</div>
                </div>
                <div className={`faq-item ${state[3]}`} onClick={()=>toggle(3)}>
                    <h5 className="faq-item_title">3 - QUEM PODE PARTICIPAR DA PROMOÇÃO?</h5>
                    <div className="faq-item_content clearfix"><p><span>É permitida a participação de pessoas jurídicas cadastradas no App Solar+, que estejam localizadas e sediadas nas cidades participantes da promoção, desde que: (I) Esteja cadastrada no App Solar+; (II) cadastre-se no aplicativo Solar+; (III) efetue ao menos uma compra através dos canais digitais Solar+ (Aplicativo), a partir da semana de participação em que o cadastro foi concluído; e (IV) preencha as condições de participação estabelecidas neste Regulamento.</span></p></div>
                </div>
                <div className={`faq-item ${state[4]}`} onClick={()=>toggle(4)}>
                    <h5 className="faq-item_title">4 - QUEM NÃO PODE PARTICIPAR DA PROMOÇÃO?</h5>
                    <div className="faq-item_content clearfix"><p><span>Ficará vedada a participação de clientes que não estejam cadastrados no Aplicativo Solar+. Esta promoção não é direcionada a pessoas físicas, mesmo elas sendo funcionárias, gestores ou proprietários dos estabelecimentos participantes, cabendo assim, apenas a participação da pessoa jurídica que atenderem os critérios do regulamento</span></p></div>
                </div>
                <div className={`faq-item ${state[5]}`} onClick={()=>toggle(5)}>
                    <h5 className="faq-item_title">5 - COMO PARTICIPAR DA PROMOÇÃO?</h5>
                    <div className="faq-item_content clearfix"><p><span>A participação na promoção “Solar+ Prêmios” será concluída a partir do momento que clientes fizeram o cadastro no Aplicativo Solar+ e que realizarem uma compra efetuada através do canal Digital Solar+ (Aplicativo).&nbsp;</span></p>
                        <br />
                        <p><span>{'1)'} Será considerada, nesta promoção, como compra efetuada, toda e qualquer compra que tenha passado pelos estágios de pedido e entrega concluída. Pedidos sem entregas concluídas não são consideradas como vendas efetivadas.</span></p>
                        <br/>
                        <p><span>{'2)'} Os participantes da promoção “Solar+ Prêmios” receberão 1 (um) número da sorte a cada compra realizada via aplicativo. Caso realizem compras de produtos aceleradores, os participantes receberão 2 (dois) números da sorte por compra.</span></p>
                        <br/>
                        <p><span>{'3)'} Os números da sorte e o período de participação serão baseados na data em que a compra for efetivada (pedido e entrega concluídos) e não na data da emissão dos pedidos. Caberá ao participante consultar o calendário de participação (item 9.1), a fim de encontrar o período que estará concorrendo aos sorteios.</span></p>
                        <br/>
                        <p><span>{'4)'} Em caso de cancelamento da compra/pedido (devolução dos produtos por desistência da compra) ou troca do produto, os números da sorte serão automaticamente invalidados para participação nos sorteios da promoção.</span></p></div>
                </div>
                <div className={`faq-item ${state[6]}`} onClick={()=>toggle(6)}>
                    <h5 className="faq-item_title">6 - POSSO CADASTRAR MEU CPF NA PROMOÇÃO?</h5>
                    <div className="faq-item_content clearfix"><p><span> a participação de pessoas jurídicas cadastradas no App Solar+, que estejam localizadas e sediadas nas cidades participantes da promoção, desde que: (I) Esteja cadastrada no App Solar+; (II) efetue ao menos uma compra através do canal digital Solar+ (Aplicativo), a partir da semana de participação em que o cadastro no site da promoção foi concluído; e (III) preencha as condições de participação estabelecidas neste Regulamento</span></p></div>
                </div>
                <div className={`faq-item ${state[7]}`} onClick={()=>toggle(7)}>
                    <h5 className="faq-item_title">7 - CONSIGO ME CADASTRAR SEM O CNPJ?</h5>
                    <div className="faq-item_content clearfix"><p><span>Não. É permitida a participação apenas de pessoas jurídicas cadastradas no App Solar+, que estejam localizadas e sediadas nas cidades participantes da promoção, desde que: (I) Esteja cadastrada no App Solar+; (II) efetue ao menos uma compra através do canal digital Solar+ (Aplicativo), a partir da semana de participação em que o cadastro no site da promoção foi concluído; e (III) preencha as condições de participação estabelecidas neste Regulamento.</span></p></div>
                </div>
                <div className={`faq-item ${state[8]}`} onClick={()=>toggle(8)}>
                    <h5 className="faq-item_title">8- OS NÚMEROS DA SORTE GERADOS SÃO VÁLIDOS POR TODO O PERÍODO DA PROMOÇÃO?</h5>
                    <div className="faq-item_content clearfix"><p><span>Sim, os números da sorte serão válidos por todo o período da promoção desde que ainda não tenham sido sorteados.</span></p></div>
                </div>
                <div className={`faq-item ${state[9]}`} onClick={()=>toggle(9)}>
                    <h5 className="faq-item_title">9 – QUANTAS VEZES EU POSSO GANHAR NA PROMOÇÃO?</h5>
                    <div className="faq-item_content clearfix"><p><span>Cada participante poderá ser contemplado apenas uma única vez em cada sorteio.</span></p></div>
                </div>
                <div className={`faq-item ${state[10]}`} onClick={()=>toggle(10)}>
                    <h5 className="faq-item_title">10 - POSSO DIVIDIR MEU PRÊMIO COM OUTRO ESTABELECIMENTO OU COM OS GERENTES E COLABORADORES DO MEU ESTABELECIMENTO?</h5>
                    <div className="faq-item_content clearfix"><p><span>Não, esta promoção é direcionada apenas aos estabelecimentos participantes que atenderem aos requisitos deste regulamento; portanto, somente o estabelecimento elegível poderá receber o prêmio.</span></p></div>
                </div>
                <div className={`faq-item ${state[11]}`} onClick={()=>toggle(11)}>
                    <h5 className="faq-item_title">11 - QUAL É O NÚMERO MÁXIMO DE NÚMEROS DA SORTE POR PARTICIPANTE?</h5>
                    <div className="faq-item_content clearfix"><p><span>Não haverá limite de números da sorte por participante, ou seja, o participante poderá concorrer com quantos números da sorte tiver direito, desde que preencham as condições e requisitos de participação estabelecidos neste Regulamento.</span></p></div>
                </div>
                <div className={`faq-item ${state[12]}`} onClick={()=>toggle(12)}>
                    <h5 className="faq-item_title">12 - COMO POSSO VERIFICAR MEUS NÚMEROS DA SORTE DEPOIS DE CADASTRÁ-LOS?</h5>
                    <div className="faq-item_content clearfix"><p><span>Os participantes poderão consultar, durante o período de participação, seus números da sorte, através do portal Solar+, em até 3 (três) dias úteis após a confirmação de entrega do pedido.</span></p></div>
                </div>
                <div className={`faq-item ${state[13]}`} onClick={()=>toggle(13)}>
                    <h5 className="faq-item_title">13 - QUANDO SERÃO REALIZADOS OS SORTEIOS?</h5>
                    <div className="faq-item_content clearfix">
                        <p><span>Semanalmente. Os sorteios serão feitos todo sábado, de 16/11/24 a 21/12/24, com o sorteio final em 11/01/25.</span></p>
                        <br/>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p><b>Data do Sorteio</b></p>
                                    </td>
                                    <td>
                                        <p><b>Evento</b></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><span>16/11/2024</span></p>
                                    </td>
                                    <td>
                                        <p><span>Sorteio Semanal</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><span>23/11/2024</span></p>
                                    </td>
                                    <td>
                                        <p><span>Sorteio Semanal</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><span>30/11/2024</span></p>
                                    </td>
                                    <td>
                                        <p><span>Sorteio Semanal</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><span>07/12/2024</span></p>
                                    </td>
                                    <td>
                                        <p><span>Sorteio Semanal</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><span>14/12/2024</span></p>
                                    </td>
                                    <td>
                                        <p><span>Sorteio Semanal</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><span>21/12/2024</span></p>
                                    </td>
                                    <td>
                                        <p><span>Sorteio Semanal</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><span>11/01/2025</span></p>
                                    </td>
                                    <td>
                                        <p><span>Sorteio Final</span></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={`faq-item ${state[14]}`} onClick={()=>toggle(14)}>
                    <h5 className="faq-item_title">14 - COMO SERÁ A APURAÇÃO PARA DEFINIR OS CONTEMPLADOS DO SORTEIO?</h5>
                    <div className="faq-item_content clearfix"><p><span>A definição da série participante se dará a partir da dezena simples do primeiro prêmio da Extração da Loteria Federal. A definição do elemento sorteável se dará a partir dos prêmios da Extração da Loteria Federal, lidos de cima para baixo, por meio da combinação das unidades simples do primeiro ao quinto prêmio.</span></p></div>
                </div>
                <div className={`faq-item ${state[15]}`} onClick={()=>toggle(15)}>
                    <h5 className="faq-item_title">15 – QUANTOS PRÊMIOS SERÃO DISTRIBUÍDOS NA PROMOÇÃO?</h5>
                    <div className="faq-item_content clearfix"><p><span>Serão distribuídos 385 (trezentos e oitenta e cinco) prêmios na promoção “Solar+ Prêmios”.</span></p></div>
                </div>
                <div className={`faq-item ${state[16]}`} onClick={()=>toggle(16)}>
                    <h5 className="faq-item_title">16 - COMO POSSO SABER SE SOU UM DOS CONTEMPLADOS DO SORTEIO?</h5>
                    <div className="faq-item_content clearfix"><p><span>Será comunicado pelo Solar+, através de notificação e entrando no botão “Solar+ Prêmios” localizado no botão “Perfil”.</span></p></div>
                </div>
                <div className={`faq-item ${state[17]}`} onClick={()=>toggle(17)}>
                    <h5 className="faq-item_title">17 - QUAL O PRAZO PARA O RECEBIMENTO DO PRÊMIO?</h5>
                    <div className="faq-item_content clearfix"><p><span>O estabelecimento comercial possui um prazo de até 30 (trinta) dias, contados a partir do envio da documentação necessária do contemplado para a Empresa Mandatária, de acordo com o Artigo 5º do Decreto 70.951/72. Esse prazo poderá ser prorrogado em se tratando de atraso decorrente dos fornecedores dos prêmios, não caracterizando qualquer descumprimento por parte das Empresas Promotoras em razão de atraso ocasionado por fornecedores.</span></p></div>
                </div>
                <div className={`faq-item ${state[18]}`} onClick={()=>toggle(18)}>
                    <h5 className="faq-item_title">18 – POSSO PEDIR MEU PRÊMIO EM DINHEIRO?</h5>
                    <div className="faq-item_content clearfix"><p><span>Nos termos da legislação em vigor, não será permitida a conversão do prêmio em dinheiro ou, ainda, a sua substituição por outro, bem como a transferência do prêmio a terceiro, que não o estabelecimento contemplado, em data anterior à sua efetiva entrega e integralização ao patrimônio deste.</span></p></div>
                </div>
                <div className={`faq-item ${state[19]}`} onClick={()=>toggle(19)}>
                    <h5 className="faq-item_title">19 – O QUE PODERÁ DESCLASSIFICAR UM ESTABELECIMENTO PARTICIPANTE</h5>
                    <div className="faq-item_content clearfix"><p><span>Será desclassificado da promoção e perderá direito ao prêmio o participante que:</span></p>
                        <p><span>I. esteja em desacordo com qualquer item elencado neste regulamento;</span></p>
                        <p><span>II. não atender aos requisitos do item 6.1;</span></p>
                        <p><span>III. cometer qualquer tipo de fraude ou ato ilícito que possa alterar o resultado da promoção, podendo ainda responder civil e criminalmente pelos seus atos, em conformidade com a legislação vigente;</span></p>
                        <p><span>IV. não tenha realizado cadastro no aplicativo Solar+;</span></p>
                        <p><span>V. forneça informações de cadastro incompletas ou incorretas;</span></p>
                        <p><span>VII. seja contemplado mais de uma vez no mesmo sorteio;</span></p>
                        <p><span>VIII. seja contemplado com o mesmo número da sorte contemplado em algum outro sorteio;</span></p>
                        <p><span>IX. esteja inadimplente com as promotoras;</span></p>
                        <p><span>X. recuse o recebimento ou não seja localizado no endereço cadastrado no Aplicativo;</span></p>
                        <p><span>XI. tenha solicitado a exclusão dos seus dados pessoais/empresariais, de identificação ou participação no período da promoção até efetiva entrega dos prêmios;</span></p>
                        <p><span>XII. tenha extinguido ou não tenha renovado contrato com a Distribuidora Solar até a efetiva entrega dos prêmios.</span></p>
                    </div>
                </div>
                <div className="faq-footer">
                    <h2>Ainda precisa de ajuda?</h2>
                    <strong>Fale com a gente</strong>
                    <p>
                        De segunda a sexta-feira, das 07:00 às 19:00 e aos sábados
                        das 07:00 às 14:00 (Horário de Brasília), exceto feriados
                        nacionais.
                    </p>
                </div>
                <div className="faq-item open">
                    <strong className="contact-line"><img className="contact-icon" src={email} alt="e-mail"></img>sac@solarbr.com.br</strong>
                </div>
                <div className="faq-item open">
                    <strong className="contact-line"><img className="contact-icon" src={phone} alt="phone"></img>0800 275 0857</strong>
                </div>
            </div>
        </div>
    );
}

export default Faq;
