import React, { useContext, useState } from 'react';

import SelectInput from '../../../components/SelectInput/SelectInput';

import useAuthAdmin from '../../../hooks/admin/useAuthAdmin';
import useFetchAllDraws from '../../../hooks/admin/useFetchAllDraws';
import useFetchResults from '../../../hooks/admin/useFetchResults';
import useSetPageTitle from '../../../hooks/useSetPageTitle';

import ResultsTable from '../../../components/Admin/ResultsTable/ResultsTable';
import PopupAddress from '../../../components/Admin/PopupAddress/PopupAddress';

import tryExportingWinners from './exports/winners';

import LinearLoader from "../../../components/Loader/LinearLoader";
import AdminContext from '../../../contexts/AdminContext';

import './styles.css';

function Acompanhar() {
    const { currentPromo } = useContext(AdminContext);

    useAuthAdmin();
    useSetPageTitle(`${currentPromo?.label ?? 'Promo Solar'} | Admin`);

    const { drawsOption } = useFetchAllDraws(currentPromo?.value);
    const [draw, setDraw] = useState('');

    const [popupAddress, setPopupAddress] = useState(false);
    const [address, setAddress] = useState({});

    const [loadingExport, setLoadingExport] = useState(false);
    const [errorExport, setErrorExport] = useState('');

    const results = useFetchResults(draw);

    return(
        <>
            { popupAddress ? <PopupAddress setPopup={setPopupAddress} address={address} /> : false }
            <div className="admin-page-container">
                <div className="admin-page-title-container">
                    <p className="admin-page-title">Acompanhar resultados</p>
                    <p className="admin-page-error-import">{errorExport}</p>
                </div>
                <div className="admin-page-select-input-container">
                    <SelectInput
                        options={drawsOption.map(el => ({ label: `Sorteio ${el.tipo.charAt(0).toUpperCase() + el.tipo.slice(1)} - ${el.data.replaceAll('-', '/')}`, value: el.id }))}
                        value={drawsOption.filter(option => option.value === draw)[0]}
                        setOption={(value) => {
                            setDraw(value.value);
                        }}
                        rubik
                    />
                    { loadingExport ? <LinearLoader /> : false }
                    {
                        results.length ?
                        <div className="admin-page-export-container-table">
                            <button className="admin-page-download-button" onClick={() => tryExportingWinners(setLoadingExport, setErrorExport, draw)}>Baixar premiados</button>
                        </div>
                        : false
                    }
                </div>
                <div style={{ marginBottom: '32px' }} />
                <ResultsTable results={results} setPopup={setPopupAddress} setAddress={setAddress} />
            </div>
        </>
    )
}

export default Acompanhar;