import React from 'react';
import axios from 'axios';

import './styles.css';

function ResultsTable({ results, setPopup, setAddress }) {
    const seeAddress = (row) => {
        setPopup(true);
        setAddress(row.registro);
    }

    const handleEdit = (e, id) => {
        axios
            .put(`${process.env.REACT_APP_API_URL}/admin/resultados/${id}`, {
                status: e.target.value
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
                }
            })
            .then((response) => {
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const downloadDocument = (url, name) => {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const objectURL = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = objectURL;
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(objectURL);
            })
            .catch(error => {
                console.error('Error downloading the file:', error);
            });
    }
    

    const downloadAllDocuments = (anexos) => {
        for (const anexo of anexos) {
            downloadDocument(anexo.url, anexo.tipo);
        }
    }

    const possibleStatus = ['analise_endereco', 'envio_pendente', 'enviado']
    return(
        <div>
            <table className="log-table">
                <thead>
                    <tr>
                        <th style={{ opacity: '0.3' }}>#</th>
                        <th>Razão Social</th>
                        <th>Número da sorte</th>
                        <th>Prêmio</th>
                        <th>Endereço</th>
                        <th>Documentação</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        results.map((row, idx) => (
                            <tr key={idx}>
                                <td>{idx + 1}</td>
                                <td>{row.client.nome}</td>
                                <td>{row.voucher}</td>
                                <td>{row.premio}</td>
                                {
                                    row.anexos.length ?
                                    <td className="admin-page-see-address" onClick={() => seeAddress(row)}>Ver endereço</td>
                                    :
                                    <td>Ainda não enviado</td>
                                }
                                {
                                    row.anexos.length ?
                                    <td className="admin-page-see-address" onClick={() => downloadAllDocuments(row.anexos)}>Baixar documentos</td>
                                    :
                                    <td>Ainda não enviada</td>
                                }
                                <td>
                                    <select onChange={(e) => handleEdit(e, row.id)}>
                                        <option>{row.status}</option>
                                        {
                                            possibleStatus
                                            .filter(status => row.status !== status)
                                            .map(option => (
                                                <option key={option}>{option}</option>
                                            ))
                                        }
                                    </select>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}

export default ResultsTable;