import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuthClient = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('accessToken')) {
            navigate('/');
        }
    }, [navigate]);

};

export default useAuthClient;
