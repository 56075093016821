import React, {useContext, useState } from "react";

import useSetPageTitle from "../../hooks/useSetPageTitle";

import './styles.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import UserContext from "../../contexts/UserContext";

import { Document, Page } from 'react-pdf'

import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

function Tos() {
    const { currentPromo } = useContext(UserContext);
    useSetPageTitle(`${currentPromo?.label} |  Regulamento`);
    const [pages, setPages] = useState([]);

    function onDocumentLoadSuccess({ numPages }) {
        setPages(Array(numPages).fill(0).map((_, i) => i+1));
    }

    return (
        <div className="tos-page-container page-container">
            <h1 className="title text-yellow">Regulamento</h1>
            <Document className="pdf" file="/regulamento.pdf" onLoadSuccess={onDocumentLoadSuccess}>
                {pages.map((p) => <Page key={p} pageNumber={p} />)}
            </Document>
        </div>
    );
}

export default Tos;
