import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchResults = (sorteioId) => {
    const [results, setResults] = useState([]);
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/admin/resultados?sorteio_id=${sorteioId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
                }
            })
            .then((response) => {
                console.log(response.data.data);
                setResults(response.data.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [sorteioId]);

    return results;
}

export default useFetchResults;