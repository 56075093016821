import React, { useState, useContext } from "react";

import './styles.css';
import '../../components/Forms/AddressForm/styles.css'

import PrizeForm from "../../components/Forms/PrizeForm/PrizeForm";
import Status from "../../components/Status/Status";

import useAuthClient from "../../hooks/useAuthClient";
import useFetchClientsResults from "../../hooks/useFetchClientsResults";
import useSetPageTitle from "../../hooks/useSetPageTitle";
import one from "../../components/PrizeInfo/recibos/1.pdf";
import two from "../../components/PrizeInfo/recibos/2.pdf";
import three from "../../components/PrizeInfo/recibos/3.pdf";
import four from "../../components/PrizeInfo/recibos/4.pdf";
import five from "../../components/PrizeInfo/recibos/5.pdf";
import six from "../../components/PrizeInfo/recibos/6.pdf";
import seven from "../../components/PrizeInfo/recibos/7.pdf";
import eight from "../../components/PrizeInfo/recibos/8.pdf";
import nine from "../../components/PrizeInfo/recibos/9.pdf";

import axios from "axios";
import UserContext from "../../contexts/UserContext";

const recibos = [one, two, three, four, five, six, seven, eight, nine];


function MyPrizes() {
    const { currentPromo } = useContext(UserContext);

    useAuthClient();
    useSetPageTitle(`${currentPromo?.label} | Prêmios`);

    const { results, pendingPrizes } = useFetchClientsResults();

    const [visibleForm, setVisibleForm] = useState({ resultId: 0, prizeId: 0, prizeName: '' });

    const [cep, setCep] = useState('');
    const [cepWarning, setCepWarning] = useState('');

    const [street, setStreet] = useState('');
    const [streetWarning, setStreetWarning] = useState('');
    const [number, setNumber] = useState('');
    const [numberWarning, setNumberWarning] = useState('');
    const [complement, setComplement] = useState('');
    const [neighborhood, setNeighborhood] = useState('');
    const [neighborhoodWarning, setNeighborhoodWarning] = useState('');
    const [state, setState] = useState('');
    const [stateWarning, setStateWarning] = useState('');
    const [city, setCity] = useState('');
    const [cityWarning, setCityWarning] = useState('');
    const [country, setCountry] = useState('');
    const [countryWarning, setCountryWarning] = useState('');

    const [phone, setPhone] = useState('');
    const [phoneWarning, setPhoneWarning] = useState('');

    const [fileIdt, setFileIdt] = useState(null);
    const [fileIdtWarning, setFileIdtWarning] = useState('');
    const [fileReceipt, setFileReceipt] = useState(null);
    const [fileReceiptWarning, setFileReceiptWarning] = useState('');
    const [fileSocialContract, setFileSocialContract] = useState(null);
    const [fileSocialContractWarning, setFileSocialContractWarning] = useState('');

    const handleCep = (inputedCep) => {
        inputedCep = inputedCep.replace('-', '');
        if (inputedCep.length > 8) return;
        if (inputedCep.length !== 8) {
            setCepWarning("Insira um CEP válido");
        } else {
            setCepWarning('');
        }
        if (inputedCep.length < 9 && /^[0-9]*$/.test(inputedCep)) {
            if (inputedCep.length > 5) {
                setCep(`${inputedCep.substring(0, 5)}-${inputedCep.substring(5)}`)
            } else {
                setCep(inputedCep);
            }
        }
    }

    const clearFields = () => {
        setCep('');
        setCepWarning('');
        setStreet('');
        setStreetWarning('');
        setNumber('');
        setNumberWarning('');
        setNeighborhood('');
        setNeighborhoodWarning('');
        setComplement('');
        setState('');
        setStateWarning('');
        setCity('');
        setCityWarning('');
        setCountry('');
        setCountryWarning('');
        setFileIdt(null);
        setFileIdtWarning('');
        setFileReceipt(null);
        setFileReceiptWarning('');
        setFileSocialContract(null);
        setFileSocialContractWarning('');
        setPhone('');
        setPhoneWarning('');
    }

    const trySendingInfo = (e) => {
        e.preventDefault();
        if (!cep.length) setCepWarning('Este é um campo obrigatório');
        setStreetWarning(!street.length ? 'Este é um campo obrigatório' : '');
        setNumberWarning(!number.length ? 'Este é um campo obrigatório' : '');
        setNeighborhoodWarning(!neighborhood.length ? 'Este é um campo obrigatório' : '');
        setCityWarning(!city.length ? 'Este é um campo obrigatório' : '');
        setStateWarning(!state.length ? 'Este é um campo obrigatório' : '');
        setCountryWarning(!country.length ? 'Este é um campo obrigatório' : '');
        setPhoneWarning(!phone.length ? 'Este é um campo obrigatório' : '');
        setFileIdtWarning(!fileIdt ? 'Este é um campo obrigatório' : '');
        setFileReceiptWarning(!fileReceipt ? 'Este é um campo obrigatório' : '');
        setFileSocialContractWarning((!fileSocialContract && visibleForm.prizeId === 7) ? 'Este é um campo obrigatório' : '');

        if (!street.length) return;
        if (!cep.length) return;
        if (!number.length) return;
        if (!neighborhood.length) return;
        if (!city.length) return;
        if (!state.length) return;
        if (!country.length) return;
        if (!phone.length) return;
        if (!fileIdt) return;
        if (!fileReceipt) return;
        if (visibleForm.prizeId === 7 && !fileSocialContract) return;

        const formData = new FormData();
        formData.append('logradouro', street);
        formData.append('numero', number);
        formData.append('complemento', complement);
        formData.append('bairro', neighborhood);
        formData.append('cidade', city);
        formData.append('uf', state);
        formData.append('cep', cep);
        formData.append('telefone', phone);
        formData.append('identidade', fileIdt);
        formData.append('recibo', fileReceipt);
        formData.append('contrato_social', fileSocialContract);
        axios
            .post(`${process.env.REACT_APP_API_URL}/client/resgate-premio/${visibleForm.resultId}`, formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                },
            }
            )
            .then((response) => {
                setVisibleForm({ resultId: 0, prizeId: 0, prizeName: '' });
                clearFields();
                window.location.reload();
            })
            .catch((err) => console.log(err));
    }

    return (
        <div className="prizes-background page-container">
            <h1 className="title text-yellow">Meus prêmios</h1>
            {
                visibleForm.resultId ?
                    <PrizeForm
                        info={visibleForm}
                        setVisibleForm={setVisibleForm}
                        clearFields={clearFields}
                        cep={cep}
                        setCep={handleCep}
                        cepWarning={cepWarning}
                        setCepWarning={setCepWarning}
                        street={street}
                        setStreet={setStreet}
                        streetWarning={streetWarning}
                        setStreetWarning={setStreetWarning}
                        number={number}
                        setNumber={setNumber}
                        numberWarning={numberWarning}
                        setNumberWarning={setNumberWarning}
                        complement={complement}
                        setComplement={setComplement}
                        neighborhood={neighborhood}
                        setNeighborhood={setNeighborhood}
                        neighborhoodWarning={neighborhoodWarning}
                        setNeighborhoodWarning={setNeighborhoodWarning}
                        state={state}
                        setState={setState}
                        stateWarning={stateWarning}
                        setStateWarning={setStateWarning}
                        city={city}
                        setCity={setCity}
                        cityWarning={cityWarning}
                        setCityWarning={setCityWarning}
                        country={country}
                        setCountry={setCountry}
                        countryWarning={countryWarning}
                        setCountryWarning={setCountryWarning}
                        phone={phone}
                        setPhone={setPhone}
                        phoneWarning={phoneWarning}
                        setPhoneWarning={setPhoneWarning}
                        onClick={trySendingInfo}
                        setFileIdt={setFileIdt}
                        fileIdtWarning={fileIdtWarning}
                        setFileIdtWarning={setFileIdtWarning}
                        setFileSocialContract={setFileSocialContract}
                        fileSocialContractWarning={fileSocialContractWarning}
                        setFileSocialContractWarning={setFileSocialContractWarning}
                        setFileReceipt={setFileReceipt}
                        fileReceiptWarning={fileReceiptWarning}
                        setFileReceiptWarning={setFileReceiptWarning}
                    />
                    : false
            }
            {
                pendingPrizes ?
                    <>
                        <div className="prizes-to-withdraw-container">
                            {
                                results.filter(prize => prize.status === "aguardando").map(e => (
                                    <div key={e.voucher}>
                                        <p className="prizes-to-withdraw-title">Parabéns, você possui prêmios disponíveis para o resgate!</p>
                                        <br></br>
                                        <p className="prizes-to-withdraw-subtitle">Para receber seu prêmio, siga os passos abaixo:</p>
                                        <br></br>
                                        <p className="prizes-to-withdraw-item"><span className="item-color">1. </span><a href={recibos[e.premio.id - 1]} download="download-recibo" className="download-color"><span  className="item-verb sub-text">BAIXE</span> e imprima o recibo.</a></p>
                                        <p className="prizes-to-withdraw-item"><span className="item-color">2.</span><span className="item-verb ">PREENCHA</span> seus dados de forma legível e assine.</p>
                                        <p className="prizes-to-withdraw-item"><span className="item-color">3.</span>Escaneie, ou tire uma foto do recibo.</p>
                                        <p className="prizes-to-withdraw-item"><span className="item-color">4.</span><span className="item-verb  sub-text" onClick={() => setVisibleForm({ resultId: e.id, prizeId: e.premio.id, prizeName: e.premio.nome })}>CLIQUE</span> no botão, preencha o formulário, anexe o recibo e <span className="item-verb">CONFIRME.</span></p>
                                        <br></br>
                                        <p className="prizes-to-withdraw-disclaimer">Assim que recebermos todos os documentos e informações, marcaremos a entrega do seu prêmio. <br></br>Preencha todos os campos com atenção, pois a documentação correta é indispensável para a realização da entrega!</p>
                                        <h1 className="winner-prize">VOCÊ GANHOU {e.premio.nome.toUpperCase()}</h1>
                                        <br></br>
                                        <div className="prize-info-container">
                                            <div className="card-prize-container" onClick={() => setVisibleForm({ resultId: e.id, prizeId: e.premio.id, prizeName: e.premio.nome })}>
                                                <p className="card-prize-prize">CLIQUE AQUI PARA PREENCHER O FORMULARIO</p>
                                            </div>
                                            <a
                                                href={recibos[e.premio.id - 1]}
                                                download="download-recibo"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="download-proof"
                                            >
                                                Baixe o recibo do prêmio
                                            </a>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    </>
                    :
                    <p className="prizes-to-withdraw-subtitle">Você não possui prêmios disponíveis para resgate</p>
            }
            <div className="prizes-table-container">
                <h2 className="title text-white">Prêmios anteriores</h2>
                <div className="card-list">
                        {
                            results
                                .map((item, index) => (
                                    <div className="card" key={index}>
                                        <span>Voucher: <strong>#{item.voucher}</strong></span>
                                        <h2><strong className="title text-white">{item.premio.nome}</strong></h2>
                                        <div className="card-content">
                                            <Status status={item.status} />
                                        </div>
                                    </div>
                                ))
                        }
                </div>
            </div>
        </div>
    )
}

export default MyPrizes;