import React from 'react';
import './styles.css';
import logo from '../../../assets/solar_mais_premios/logo_white.png';
import { Link } from 'react-router-dom';

function Footer() {
    return (
      <div className='footer-container'>
        <div className='footer-logo-container'>
          <img
            alt='logo'
            className='footer-logo'
            src={logo}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          />
        </div>
        <div className='footer-links'>
          <Link
            to='/faq'
          >
            FAQ
          </Link>
          <Link
            to='/regulamento'
          >
            Regulamento
          </Link>
        </div>
        <hr className='footer-divider'></hr>
        <div className='footer-burocracy'>
          <p>
            *Beba com moderação. Venda e consumo proibidos para menores de 18 anos. Imagens meramente ilustrativas. Promoção autorizada pela SPA/ME. Promoção válida de 18.11.2024 a 25.01.2025 ou enquanto durarem os estoques. Promoção para maiores de 18 (dezoito) anos, válida nos estados de AC, AP, AM, PA, RO, RR, TO, AL, BA, CE, MA, PB, PE, PI, RN, SE, GO, MT. Produtos participantes: Todo o portfólio disponível no Solar+, em todas as suas apresentações. Consulte cidades participantes, descrição, valor dos prêmios e condições de participação, acessando o regulamento da promoção, disponível em solarmaispremios.com.
          </p>
        </div>
      </div>
    );
}

export default Footer;
