import React from 'react';

import './styles.css';

import search from '../../assets/icons/search-red.png';

function SearchBar({ placeholder, value, setValue, rubik }) {
    return(
        <div className="search-bar-container">
            <img src={search} className="search-icon" alt="search" />
            <input
                className="search-bar-input"
                type='text'
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder={placeholder}
                style={{ fontFamily: rubik ? 'Rubik' : 'Archivo' }}
            />
        </div>
    )
}

export default SearchBar;