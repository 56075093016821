import React from "react";

import "./styles.css";

function DrawBox({ name, startDate, endDate, disabled, selected, onClick }) {
    let style = 'draw-box-container';
    if (selected) style = "draw-box-container-selected"
    if (disabled) style = "draw-box-container-disabled"
    return(
        <div className={style} onClick={onClick}>
            <p className="draw-box-title">{name}</p>
            {startDate && endDate && <>
                <p>Período de participação:</p>
                <p>{startDate} a {endDate}</p>
            </>}
        </div>
    )
} 

export default DrawBox;