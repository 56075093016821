import React, { useContext, useState } from "react";

import Status from "../../components/Status/Status";
import SelectInput from "../../components/SelectInput/SelectInput";
import SearchBar from '../../components/TextInput/SearchBar';

import useAuthClient from '../../hooks/useAuthClient';
import useFetchVouchers from "../../hooks/useFetchVouchers";
import useSetPageTitle from "../../hooks/useSetPageTitle";

import './styles.css';

import arrow from '../../assets/icons/arrow.png';
import UserContext from "../../contexts/UserContext";

function MyNumbers() {
    const { currentPromo } = useContext(UserContext);

    useAuthClient();
    useSetPageTitle(`${currentPromo?.label} | Números`);
    const [numbers, setNumbers] = useState([]);
    useFetchVouchers(setNumbers);

    const [filter, setFilter] = useState('Todos');
    const [searchNumber, setSearchNumber] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const rowOptions = [
        { value: '10', label: '10 linhas por página' },
        { value: '50', label: '50 linhas por página' },
        { value: '100', label: '100 linhas por página' },
        { value: '200', label: '200 linhas por página' },
    ]

    const handleSearch = (text) => {
        setPage(1);
        if (text.length <= 10) setSearchNumber(text);
    }

    const handlePageChange = (increment) => {
        const totalNumbers = 
            numbers
                .filter(number => {
                    if (filter === 'Todos') return true;
                    if (filter === 'Sorteados') return number.status === 'sorteado';
                    if (filter === 'Aguardando') return number.status === 'aguardando'
                    return false;
                })
                .length;
        if (page + increment <= Math.ceil(totalNumbers/rowsPerPage) && (page + increment > 0)) {
            setPage(page + increment);
        }
    }

    return(
        <div className="numbers-background page-container">
            <h1 className="title text-yellow">Meus números da sorte</h1>
            <div className="numbers-table-container">
                <div className="numbers-table-filters-container">
                    <div className={filter === 'Todos' ? "numbers-table-filter-selected" : "numbers-table-filter"} onClick={() => setFilter('Todos')}>
                        <p>Todos</p>
                        <div className={filter === 'Todos' ? "numbers-table-filter-quantity-selected" : "numbers-table-filter-quantity"}>
                            <p>{numbers.length}</p>
                        </div>
                    </div>
                    <div className={filter === 'Aguardando' ? "numbers-table-filter-selected" : "numbers-table-filter"} onClick={() => {
                        setFilter('Aguardando');
                        setPage(1);
                    }}>
                        <p>Aguardando</p>
                        <div className={filter === 'Aguardando' ? "numbers-table-filter-quantity-selected" : "numbers-table-filter-quantity"}>
                            <p>{numbers.filter(number => number.status === 'aguardando').length}</p>
                        </div>
                    </div>
                    <div className={filter === 'Sorteados' ? "numbers-table-filter-selected" : "numbers-table-filter"} onClick={() => {
                        setFilter('Sorteados');
                        setPage(1);
                    }}>
                        <p>Sorteados</p>
                        <div className={filter === 'Sorteados' ? "numbers-table-filter-quantity-selected-green" : "numbers-table-filter-quantity"}>
                            <p>{numbers.filter(number => number.status === 'sorteado').length}</p>
                        </div>
                    </div>
                </div>
                <div className="numbers-table-search-container">
                    <SearchBar value={searchNumber} setValue={handleSearch} placeholder="Procure um número" />
                </div>
                <div className="card-list">
                    {numbers
                        .filter(number => {
                            if (filter === 'Todos') return true;
                            if (filter === 'Sorteados') return number.status === 'sorteado';
                            if (filter === 'Aguardando') return number.status === 'aguardando'
                            return false;
                        })
                        .filter(number => number.code.includes(searchNumber))
                        .filter((number, index) => (index >= (page - 1) * rowsPerPage && index < page * rowsPerPage))
                        .map((item, index) => (
                            <div className="card" key={index}>
                                <span>Data: {item.data_pedido}</span>
                                <h2><strong className="title text-white">#{item.code}</strong></h2>
                                <div className="card-content">
                                    <Status status={item.status} />
                                </div>
                            </div>
                        ))}
                </div>
                <div className="numbers-table-search-container">
                    <div className="numbers-table-pagination-container">
                        <SelectInput
                            options={rowOptions}
                            value={rowOptions.filter(option => option.value === String(rowsPerPage))}
                            setOption={(value) => {
                                setPage(1);
                                setRowsPerPage(Number(value.value));
                            }}
                        />
                        <img src={arrow} alt="arrow-icon" className={`numbers-table-arrow-icon ${page === 1 ? "numbers-table-arrow-icon-disabled" : ""}`} onClick={() => handlePageChange(-1)} />
                        <p className="numbers-table-page">{page}</p>
                        <img src={arrow} alt="arrow-icon" className={`numbers-table-arrow-reverse-icon ${page === Math.ceil(numbers.length / rowsPerPage) ? "numbers-table-arrow-icon-disabled" : ""}`} onClick={() => handlePageChange(1)} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyNumbers;