import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";

import axios from 'axios';

import Checkbox from "../../components/Checkbox/Checkbox";
import LinearLoader from "../../components/Loader/LinearLoader";
import useSetPageTitle from "../../hooks/useSetPageTitle";

import './styles.css';

import danger from '../../assets/icons/danger.png';
import UserContext from "../../contexts/UserContext";

function Signup() {
    const { currentPromo, setCurrentUser } = useContext(UserContext);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useSetPageTitle(`${currentPromo?.label} |  Cadastro`);

    const [clientId, setClientId] = useState(0);
    const [doc, setDoc] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [client, setClient] = useState({});

    const [agreeWithRegulation, setAgreeWithRegulation] = useState(false);
    const [agreeWithRegulationWarning, setAgreeWithRegulationWarning] = useState(null);
    const [agreeWithSharing, setAgreeWithSharing] = useState(false);
    const [agreeWithSharingWarning, setAgreeWithSharingWarning] = useState(null);

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(''); // Adicione o estado da mensagem de erro

    const scrollToTop = () => window.scrollTo({ top: 120, behavior: 'smooth' });

    useEffect(() => {
        if (!searchParams.has('clientId')) {
            setErrorMessage('Cliente não identificado');
            setClient(null);
            return;
        }

        const cId = searchParams.get('clientId');
        setClientId(cId);

        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/solar-client/${cId}`);

                // Verifique se a resposta contém um array na chave "data"
                if (response.data) {
                    const res = response.data;
                    console.log(response.data);
                    setDoc(res.cnpjCpf);
                    setName(res.name);
                    setEmail(res.email);
                    setPhone(res.celular)
                    setClient(res);
                } else {
                    setClient(null);
                    setErrorMessage("#1 - Cliente não encontrado: " + cId)
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setClient(null);
                setErrorMessage("#2 - Cliente não encontrado: " + cId)
            }
        };

        fetchData();
    }, [searchParams, navigate]);

    const onSubmit = (e) => {
        e.preventDefault();
        if (loading) {
            return;
        }

        if (!agreeWithRegulation) setAgreeWithRegulationWarning('Você precisa aceitar o regulamento');
        if (!agreeWithRegulation) {
            return;
        }

        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/solar-client/${clientId}/apply-promo`, {
                doc: doc,
                email: email,
                nome: name,
                telefone: phone,
            })
            .then((response) => {
                setLoading(false);
                if (response.status === 200) {
                    setCurrentUser(response.data.client, response.data.token)
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'cadastro-ok',
                    });
                    navigate(`/?clientToken=${response.data.token}&success=true`);
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.status === 400) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'error_event',
                        error_code: 400,
                        error_message: 'Elegível já possui cadastro - 400'
                    });
                    setErrorMessage('Elegível já possui cadastro - 400');
                    scrollToTop();
                    return;
                }
                setErrorMessage('Ocorreu um erro. Tente novamente mais tarde.');
            });
    }

    return (
        <div className="signup-page-container page-container">
            <form className="signup-container" onSubmit={(e) => onSubmit(e)} method="post">
                <h1 className="title text-yellow">Cadastre-se</h1>
                <span className="signup-text"><strong>Finalize o seu cadastro</strong> para participar da promoção <strong>Solar+ Prêmios!</strong></span>
                <div className="signup-form-container">
                    {
                        errorMessage && (
                            <div className="signup-form-invalid-cpf-border">
                                <div className="signup-form-error-container">
                                    <img src={danger} alt="danger" className="signup-form-invalid-cpf-warning-img" />
                                    <h3>Atenção!</h3>
                                    <p>{errorMessage}</p>
                                </div>
                            </div>
                        )
                    }
                    <div className="signup-form-inputs-container">
                        <div className="signup-form-checkbox-container">
                            <Checkbox
                                value={agreeWithRegulation}
                                setValue={setAgreeWithRegulation}
                                warning={agreeWithRegulationWarning}
                                setWarning={setAgreeWithRegulationWarning}
                                text='Estou ciente e concordo integralmente com o Regulamento da Promoção, com os Termos e com a Política de Privacidade da Solar Coca-Cola, bem como estou ciente e dou autorização para que a Solar Coca-Cola use meus dados de contato e interações para me mandar comunicações referente a promoção que estou me cadastrando e compartilhe meus dados com empresas parceiras, respeitadas as diretrizes da Lei Geral de Proteção de Dados (Lei 13.709/18).' />
                            <Checkbox
                                value={agreeWithSharing}
                                setValue={setAgreeWithSharing}
                                warning={agreeWithSharingWarning}
                                setWarning={setAgreeWithSharingWarning}
                                text='Concordo que a Solar Coca-Cola use meus dados de contato e interações para me mandar comunicações de marketing e informações sobre produtos.' />
                        </div>
                    </div>
                    <button type="submit" className="cta" disabled={client == null}>
                        {
                            loading ? <LinearLoader white /> : <p>Participar da Promoção</p>
                        }
                    </button>
                </div>
            </form>
            
        </div>
    );
}

export default Signup;
