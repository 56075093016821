import { createContext } from 'react';
import { useState } from "react";

export function useUserContext() {
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem('profile') ?? 'null'));

    const setCurrentUser = (profile, token) => {
        localStorage.setItem('accessToken', token);
        localStorage.setItem('profile', JSON.stringify(profile));
        setProfile(profile)
    }

    return { currentUser: profile, setCurrentUser, currentPromo: {value: 5, label: 'Solar+ Prêmios'} }
};

const UserContext = createContext({});
export default UserContext;
