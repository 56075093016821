import { useEffect, useState } from 'react';
import axios from 'axios';

const useFetchLogs = (setLoading, setError) => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin/logs`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
        },
      })
      .then((response) => {
        setLoading(false);
        setError(false);
        setLogs(response.data.data);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }, [setLoading, setError]);

  return { logs };
};

export default useFetchLogs;
