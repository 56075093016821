import axios from 'axios';


const downloadCsv = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'resultados-atual');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const tryExportingVouchers = (promoId, setLoading, setErrorExport) => {
    setLoading(true);
    axios
        .get(`${process.env.REACT_APP_API_URL}/admin/vouchers/export?promo_id=${promoId}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
            },
        })
        .then((response) => {
            downloadCsv(response.data.url);
            setLoading(false);
        })
        .catch((err) => {
            setErrorExport(err.response.data.message);
            setLoading(false);
        });
}

export default tryExportingVouchers;
