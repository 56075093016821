import React from "react";
import Select from 'react-select';

function SelectInput({
    options, setOption, value, rubik
}) {
    return(
        <Select 
        options={options} 
        value={value}
        onChange={setOption}
        styles={{
            control: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: '1.12rem',
                width: '100%',
                padding: '6px 10px',
                borderRadius: '20px',
                fontFamily: rubik ? 'Rubik' : 'Archivo',
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => (
            {
                ...styles,
                fontSize: '1rem',
                color: 'black',
                    fontFamily: rubik ? 'Rubik' : 'Archivo'
            })
        }} 
        theme={(theme) => ({
            ...theme,
            borderRadius: 6,
            colors: {
            ...theme.colors,
            primary25: '#fbfbfb',
            primary: 'rgba(218, 41, 28, 0.2)',
                fontFamily: rubik ? 'Rubik' : 'Archivo'
            },
        })}/>
    )
}

export default SelectInput;