import { Outlet } from 'react-router-dom';
import AdminContext, { useAdminContext } from '../../contexts/AdminContext';
import { useEffect } from 'react';
import axios from 'axios';
import SideBarAdmin from './SideBar/SideBar';

const AdminLayout = () => {

    const context = useAdminContext(); 

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/promos`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
                    }
                });

                if (Array.isArray(response.data)) {
                    const promoList = response.data.sort((a, b) => b.id - a.id);
                    const curPromo = promoList.find((p) => p?.status === 'active');
                    const promoOptions = promoList.map(el => ({ label: el.titulo, value: el.id }));
                    const curOption = promoOptions.find(p => p.value === curPromo.id) ?? promoOptions.at(0);
                    context.setPromos(promoOptions);
                    context.setCurrentPromo(curOption)
                } else {
                    console.error('Data is not an array:', response.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <AdminContext.Provider value={context}>
            <div className="admin-home-background">
                <SideBarAdmin />
                <Outlet />
            </div>
        </AdminContext.Provider>
    );
};

export default AdminLayout;