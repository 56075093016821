import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Swal from 'sweetalert2'

import './styles.css';

function EmailTable({ search, currentPage, setCurrentPage, setPopup, setAddress }) {
    const [results, setResults] = useState([]);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        // Função para buscar os dados da API
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/recupera-emails?search=${search}&page=${currentPage}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
                    }
                });

                // Verifique se a resposta contém um array na chave "data"
                if (Array.isArray(response.data.data)) {
                    setResults(response.data.data);
                    setTotalPages(response.data.meta.last_page); // Atualize o total de páginas
                } else {
                    console.error('Data is not an array:', response.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [search, currentPage]);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const handleEdit = async (id) => {
        const { value: accept } = await Swal.fire({
            title: "Alterar Email?",
            input: "checkbox",
            inputValue: 1,
            inputPlaceholder: `
              Eu permito a alteração do e-mail
            `,
            confirmButtonText: `
              Continue&nbsp;<i class="fa fa-arrow-right"></i>
            `,
            inputValidator: (result) => {
              return !result && "Para cancelar clique fora da caixa";
            }
          });
          if (accept) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/recupera-emails/${id}`, {}, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
                    }
                });
                console.log('Edit success:', response.data);
                Swal.fire("Email Alterado");
            } catch (error) {
                console.error('Error editing data:', error);
                Swal.fire("Ocorreu um erro:", error.message);
            }
          }
    }

    const handleDelete = async (id) => {
        const { value: accept } = await Swal.fire({
            title: "Deletar Pedido?",
            input: "checkbox",
            inputValue: 1,
            inputPlaceholder: `
              Eu permito deletar esse pedido
            `,
            confirmButtonText: `
              Continue&nbsp;<i class="fa fa-arrow-right"></i>
            `,
            inputValidator: (result) => {
              return !result && "Para cancelar clique fora da caixa";
            }
          });
          if (accept) {
            try {
                const response = await axios.delete(`${process.env.REACT_APP_API_URL}/admin/recupera-emails/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
                    }
                });
                console.log('Delete success:', response.data);
                // Remove the deleted item from the state
                setResults(results.filter(result => result.id !== id));
                Swal.fire("Pedido Deletado");
            } catch (error) {
                console.error('Error deleting data:', error);
                Swal.fire("Ocorreu um erro:", error.message);
            }
          }
    }

    return (
        <div>
            <table className="log-table">
                <thead>
                    <tr>
                        <th style={{ opacity: '0.3' }}>#</th>
                        <th>Email Antigo</th>
                        <th>Email Novo</th>
                        <th>CPF/CNPJ</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Array.isArray(results) && results.length > 0 ? (
                            results.map((row, idx) => (
                                <tr key={idx}>
                                    <td>{idx + 1}</td>
                                    <td>{row.old_email}</td>
                                    <td>{row.new_email}</td>
                                    <td>{row.doc}</td>
                                    <td>
                                        <button class = "alterar-button" onClick={() => handleEdit(row.id)}>Alterar</button>
                                        <button class = "deletar-button" onClick={() => handleDelete(row.id)}>Remover</button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5">No data available</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
            <div className="pagination-controls">
                <button class = "paginate-button" onClick={handlePreviousPage} disabled={currentPage === 1}>{"<"}</button>
                <span>Página {currentPage} de {totalPages}</span>
                <button class = "paginate-button" onClick={handleNextPage} disabled={currentPage === totalPages}>{">"}</button>
            </div>
        </div>
    );
}

export default EmailTable;
