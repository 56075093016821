import React, { useContext, useState } from "react";
import SelectInput from "../../../components/SelectInput/SelectInput";

import './styles.css';

import FileInput from "../../../components/Admin/FileInput/FileInput";
import DownloadModel from "../../../components/Admin/DownloadModel/DownloadModel";
import RadioButtons from "../../../components/Admin/RadioButtons/RadioButtons";

import tryExportingResults from "./exports/results";
import tryImportingResults from "./imports/results";

import useFetchAllDraws from "../../../hooks/admin/useFetchAllDraws";
import useAuthAdmin from "../../../hooks/admin/useAuthAdmin";
import useCheckRoleActions from "../../../hooks/admin/useCheckRoleActions";
import useSetPageTitle from "../../../hooks/useSetPageTitle";

import DefaultLoader from "../../../components/Loader/DefaultLoader";
import LinearLoader from "../../../components/Loader/LinearLoader";
import AdminContext from "../../../contexts/AdminContext";

function ResultsAdmin() {
    const { currentPromo } = useContext(AdminContext);

    useAuthAdmin();
    useSetPageTitle(`${currentPromo?.label ?? 'Promo Solar'} | Admin`);
    
    const [option, setOption] = useState(0);
    const [radioButtonsList, setRadioButtonsList] = useState(['Atualizar resultados com o arquivo enviado', 'Substituir base de resultados pelo arquivo enviado*']);
    const [draw, setDraw] = useState('');

    useCheckRoleActions(setRadioButtonsList);

    const { drawsOption } = useFetchAllDraws(currentPromo?.value);

    const [file, setFile] = useState(null);
    const [loadingImport, setLoadingImport] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [errorImport, setErrorImport] = useState('');
    const [errorExport, setErrorExport] = useState('');
    const [successImport, setSuccessImport] = useState('');

    const downloadModel = () => {
        const link = document.createElement('a');
        link.href = "/models/modelo-resultados.csv";
        link.download = "modelo-resultados.csv";
        link.click();
    };

    return(
        <div className="admin-page-container">
            <div className="admin-page-title-container">
                <p className="admin-page-title">Importar números da sorte premiados</p>
                {
                    localStorage.getItem('role') !== '1' ?
                    <DownloadModel label="Baixe o modelo de planilha" onClick={downloadModel} />
                    : false
                }
            </div>
            {
                localStorage.getItem('role') !== '1' ?
                <>
                    <div className="admin-page-select-input-container">
                        <SelectInput
                            options={drawsOption.map(el => ({ label: `Sorteio ${el.tipo.charAt(0).toUpperCase() + el.tipo.slice(1)} - ${el.data.replaceAll('-', '/')}`, value: el.id }))}
                            value={drawsOption.filter(option => option.value === draw)[0]}
                            setOption={(value) => {
                                setDraw(value.value);
                            }}
                            rubik
                        />
                    </div>
                    <div style={{ marginBottom: '20px' }} />
                    <FileInput setValue={setFile} />
                    <RadioButtons
                        radioButtonsList={radioButtonsList}
                        option={option}
                        setOption={setOption}
                    />
                    <div className="admin-page-button-container">
                        <button className="admin-page-primary-button" onClick={() => tryImportingResults(file, draw, setLoadingImport, setErrorImport, setSuccessImport)}>Enviar</button><br />
                        { loadingImport ? <DefaultLoader /> : false }
                    </div>
                    <p className="admin-page-error-import">{errorImport}</p>
                    <p className="admin-page-success-import">{successImport}</p>
                </>
                : false
            }
            <div className="admin-page-export-container">
                <button className="admin-page-secundary-button" onClick={() => tryExportingResults(setLoadingExport, setErrorExport)}>Baixar resultados <span className="button-bold">atuais</span></button>
                { loadingExport ? <LinearLoader /> : false }
            </div>
            <p className="admin-page-error-import">{errorExport}</p>
        </div>
    )
}

export default ResultsAdmin;