import axios from 'axios';

    
const tryImportingResults = (file, draw, setLoading, setErrorImport, setSuccessImport) => {
    if (!file) {
        setErrorImport('Arquivo obrigatório.');
        setSuccessImport('');
        return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('sorteio_id', Number(draw));
    axios
        .post(`${process.env.REACT_APP_API_URL}/admin/resultados`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem('adminAccessToken')}`
            },
        })
        .then((response) => {
            setLoading(false);
            setSuccessImport('Sua base foi importada com sucesso.');
            setErrorImport('');
        })
        .catch((err) => {
            setSuccessImport('');
            setErrorImport(err.response.data.message);
            setLoading(false);
        })
}

export default tryImportingResults;