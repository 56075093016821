import { Outlet, useLocation } from 'react-router-dom';
import UserContext, { useUserContext } from '../../contexts/UserContext';
import Footer from '../../components/Home/Footer/Footer';
import NavBar from '../NavBar/NavBar';
import './styles.css';
import { useEffect } from 'react';

const UserLayout = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [location]);

    const contextValue = useUserContext(); 
    return (
        <UserContext.Provider value={contextValue}>
            <div className="background">
                <Outlet />
                <Footer />
            </div>
            <NavBar />
        </UserContext.Provider>
    );
};

export default UserLayout;