import React, { useContext, useState } from "react";

import DrawBox from "../../components/DrawBox/DrawBox";
import SearchBar from '../../components/TextInput/SearchBar';

import useAuthClient from '../../hooks/useAuthClient';
import useFetchDraws from "../../hooks/useFetchDraws";
import useFetchResults from "../../hooks/useFetchResults";
import useSetPageTitle from "../../hooks/useSetPageTitle";

import arrow from '../../assets/icons/arrowdown.png';
import './styles.css';
import UserContext from "../../contexts/UserContext";

function Results() {
    const { currentPromo } = useContext(UserContext);

    useAuthClient();
    useSetPageTitle(`${currentPromo?.label} | Resultados`);

    const [dropdown, setDropdown] = useState(false);
    const [searchInfo, setSearchInfo] = useState('');
    const [drawsWeek, setDrawsWeek] = useState([]);
    const [drawsMonth, setDrawsMonth] = useState([]);
    const [drawsFinal, setDrawsFinal] = useState([]);

    const [sorteio, setSorteio] = useState(null);

    useFetchDraws(currentPromo?.value, setDrawsWeek, setDrawsMonth, setDrawsFinal, setSorteio);
    const results = useFetchResults(sorteio?.id);

    const formatDate = (unformattedDate) => unformattedDate.split('-').reverse().join('/');

    return(
        <div className="results-background page-container">
            <h1 className="title text-yellow">Selecione o Sorteio e <br />Confira os ganhadores</h1>
            <div className="results-dropdown-element">
                <div className={dropdown ? "results-dropdown-selected" : "results-dropdown"} onClick={() => setDropdown(!dropdown)}>
                    <img src={arrow} alt="arrow-down" className={dropdown ? "results-dropdown-arrow-up" : "results-dropdown-arrow"} />
                    {sorteio && <p>{`Sorteio ${sorteio.tipo.charAt(0).toUpperCase() + sorteio.tipo.slice(1)} - ${formatDate(sorteio.data)}`}</p>}
                    {!sorteio && <p>Selecione um Sorteio</p>}
                </div>
                <div className={dropdown ? "results-dropdown-container-visible" : "results-dropdown-container-hidden"}>
                    <div className={dropdown ? "results-dropdown-content-visible" : "results-dropdown-content-hidden"}>
                    <p className="results-dropdown-subtitle">Resultados dos Sorteios Semanais</p>
                        <div className="results-dropdown-draw-boxes-container">
                            {!drawsWeek.length && <DrawBox
                                key={`Sorteio semanal`}
                                name={`Nenhum sorteio semanal encontrado`}
                                disabled={true}
                            />
                            }
                            {drawsWeek.map(draw => (
                                <DrawBox
                                    key={`Sorteio semanal - ${draw.data}`}
                                    name={`Sorteio semanal - ${formatDate(draw.data)}`}
                                    startDate={formatDate(draw.inicio)}
                                    endDate={formatDate(draw.fim)}
                                    selected={draw.selected}
                                    disabled={draw.status === 'nao_realizado'}
                                    onClick={() => {
                                        if (draw.status !== 'nao_realizado') {
                                            setDropdown(false);
                                            setSorteio(draw);
                                        }
                                    }}
                                />
                            ))}
                        </div>
                        <p className="results-dropdown-subtitle">Resultados dos Sorteios Mensais</p>
                        <div className="results-dropdown-draw-boxes-container">
                            {!drawsMonth.length && <DrawBox
                                key={`Sorteio mensal`}
                                name={`Nenhum sorteio mensal encontrado`}
                                disabled={true}
                            />
                            }
                            {
                                drawsMonth.map(draw => (
                                    <DrawBox
                                        key={`Sorteio mensal - ${draw.data}`}
                                        name={`Sorteio mensal - ${formatDate(draw.data)}`}
                                        startDate={formatDate(draw.inicio)}
                                        endDate={formatDate(draw.fim)}
                                        selected={draw.selected}
                                        disabled={draw.status === 'nao_realizado'}
                                        onClick={() => {
                                            if (!draw.disabled && !draw.selected) {
                                                if (draw.status !== 'nao_realizado') {
                                                    setDropdown(false);
                                                    setSorteio(draw);
                                                }
                                            }
                                        }}
                                    />
                                ))
                            }
                        </div>
                        <p className="results-dropdown-subtitle">Resultados do Sorteio Final</p>
                        <div className="results-dropdown-draw-boxes-container">
                            {!drawsFinal.length && <DrawBox
                                key={`Sorteio final`}
                                name={`Nenhum sorteio final encontrado`}
                                disabled={true}
                            />
                            }
                            {
                                drawsFinal.map(draw => (
                                    <DrawBox
                                        key={`Sorteio final - ${draw.data}`}
                                        name={`Sorteio final - ${formatDate(draw.data)}`}
                                        startDate={formatDate(draw.inicio)}
                                        endDate={formatDate(draw.fim)}
                                        selected={draw.selected}
                                        disabled={draw.status === 'nao_realizado'}
                                        onClick={() => {
                                            if (draw.status !== 'nao_realizado') {
                                                setDropdown(false);
                                                setSorteio(draw);
                                            }
                                        }}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="results-table-container">
                {sorteio && <p className="title text-white">{`Sorteio ${sorteio.tipo.charAt(0).toUpperCase() + sorteio.tipo.slice(1)} - ${formatDate(sorteio.data)}`}</p>}
                {!sorteio && <p className="title text-white">Selecione um Sorteio</p>}
                <div className="results-table-search-container">
                    <SearchBar 
                        value={searchInfo}
                        setValue={setSearchInfo}
                        placeholder="Procure informações"
                    />
                </div>
                <table className="results-table">
                    <thead>
                        <tr>
                            <th style={{ opacity: '0.3', width: '120px', padding: '0px' }}>#</th>
                            <th>Razão social</th>
                            <th>Número da sorte</th>
                            <th>Prêmio</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        results
                            .filter(item => item.client_id === Number(localStorage.getItem('id')))
                            .map((item, index) => (
                                <tr key={index - 100} className="table-you-win-row">
                                    <td style={{ opacity: '0.3' }}>!</td>
                                    <td>{item.razao_social}</td>
                                    <td>{item.voucher}</td>
                                    <td>{item.premio.nome}</td>
                                </tr>
                            ))
                    }
                    {
                        results
                            .filter(item => item.client_id !== Number(localStorage.getItem('id')))
                            .filter(item => (
                                String(item.register).includes(searchInfo) ||
                                item.razao_social.toLowerCase().includes(searchInfo.toLowerCase()) ||
                                String(item.voucher).includes(searchInfo) ||
                                item.premio.nome.toLowerCase().includes(searchInfo.toLowerCase())
                            ))
                            .map((item, index) => (
                                <tr key={index}>
                                    <td style={{ opacity: '0.3' }}>{index + 1}</td>
                                    <td>{item.razao_social}</td>
                                    <td>{item.voucher}</td>
                                    <td>{item.premio.nome}</td>
                                </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Results;