import React from 'react';
import './styles.css';

function Checkbox({ value, setValue, text, warning, setWarning, }) {
    return(
        <div className="checkbox-container">
            <div className="checkbox-box-container" onClick={() => 
                setValue((prev) => {
                    if (!prev) setWarning(null);
                    return !prev;
                })
            }>
                <input className="checkbox-box" type="checkbox" checked={value} readOnly />
            </div>
            <div className="checkbox-text-container" onClick={() => 
                setValue((prev) => {
                    if (!prev) setWarning(null);
                    return !prev;
                })
            }>
                <p className="checkbox-text">{text} <span className="checkbox-warning">{warning ?? <>&nbsp;</>}</span></p>
            </div>
        </div>
    )
}

export default Checkbox;