import React, { useContext, useState } from "react";

import './styles.css';

import FileInput from "../../../components/Admin/FileInput/FileInput";
import DownloadModel from "../../../components/Admin/DownloadModel/DownloadModel";
import RadioButtons from "../../../components/Admin/RadioButtons/RadioButtons";

import tryExportingVouchers from "./exports/vouchers";
import tryImportingVouchers from "./imports/vouchers";

import useAuthAdmin from "../../../hooks/admin/useAuthAdmin";
import useCheckRoleActions from "../../../hooks/admin/useCheckRoleActions";
import useSetPageTitle from "../../../hooks/useSetPageTitle";

import DefaultLoader from "../../../components/Loader/DefaultLoader";
import LinearLoader from "../../../components/Loader/LinearLoader";
import AdminContext from "../../../contexts/AdminContext";

function VouchersAdmin() {
    const { currentPromo } = useContext(AdminContext);
    
    useAuthAdmin();
    useSetPageTitle(`${currentPromo?.label ?? 'Promo Solar'} | Admin`);
    
    const [option, setOption] = useState(0);
    const [radioButtonsList, setRadioButtonsList] = useState(['Atualizar números da sorte com o arquivo enviado', 'Substituir base de números da sorte pelo arquivo enviado']);
    
    useCheckRoleActions(setRadioButtonsList);
    
    const [file, setFile] = useState(null);
    const [loadingImport, setLoadingImport] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [errorImport, setErrorImport] = useState('');
    const [errorExport, setErrorExport] = useState('');
    const [successImport, setSuccessImport] = useState('');

    const downloadModel = () => {
        const link = document.createElement('a');
        link.href = "/models/modelo-vouchers.csv";
        link.download = "modelo-vouchers.csv";
        link.click();
    };

    return(
        <div className="admin-page-container">
            <div className="admin-page-title-container">
                <p className="admin-page-title">Importar números da sorte</p>
                {
                    localStorage.getItem('role') !== '1' ?
                    <DownloadModel label="Baixe o modelo de planilha" onClick={downloadModel} />
                    : false
                }
            </div>
            {
                localStorage.getItem('role') !== '1' ?
                <>
                    <FileInput setValue={setFile} />
                    <RadioButtons 
                        radioButtonsList={radioButtonsList}
                        option={option}
                        setOption={setOption} 
                    />
                    <div className="admin-page-button-container">
                        <button className="admin-page-primary-button" onClick={() => tryImportingVouchers(currentPromo?.value, file, setLoadingImport, setErrorImport, setSuccessImport)}>Enviar</button><br />
                        { loadingImport ? <DefaultLoader /> : false }
                    </div>
                    <p className="admin-page-error-import">{errorImport}</p>
                    <p className="admin-page-success-import">{successImport}</p>
                </>
                : false
            }
            <div className="admin-page-export-container">
                <button className="admin-page-secundary-button" onClick={() => tryExportingVouchers(currentPromo?.value, setLoadingExport, setErrorExport)}>Baixar números da sorte <span className="button-bold">atuais</span></button>
                { loadingExport ? <LinearLoader /> : false }
            </div>
            <p className="admin-page-error-import">{errorExport}</p>
        </div>
    )
}

export default VouchersAdmin;