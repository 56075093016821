import React, { useContext, useState } from "react";

import DrawTable from "../../../components/Admin/DrawTable/DrawTable";
import useAuthAdmin from "../../../hooks/admin/useAuthAdmin";
import DefaultLoader from "../../../components/Loader/DefaultLoader";

import useFetchEachDraw from "../../../hooks/admin/useFetchEachDraws";
import useSetPageTitle from "../../../hooks/useSetPageTitle";

import addrow from '../../../assets/icons/addrow.png';
import './styles.css';
import AdminContext from "../../../contexts/AdminContext";

function DrawsAdmin() {
    const { currentPromo } = useContext(AdminContext);

    useAuthAdmin();
    useSetPageTitle(`${currentPromo?.label ?? 'Promo Solar'} | Admin`);

    const [drawsMonth, setDrawsMonth] = useState([]);
    const [addingMonth, setAddingMonth] = useState(false);
    const [drawsWeek, setDrawsWeek] = useState([]);
    const [addingWeek, setAddingWeek] = useState(false);
    const [drawsFinal, setDrawsFinal] = useState([]);
    const [addingFinal, setAddingFinal] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const addDraw = (draws, setDraws, drawType, setAdding) => {
        setAdding(true);
        setDraws((prev) => [...prev, {
            tipo: drawType,
            data: '',
            inicio: '',
            fim: '',
            status: 'nao_realizado',
            editable: true,
        }]);
    }

    useFetchEachDraw(currentPromo?.value, setDrawsWeek, setDrawsMonth, setDrawsFinal, setLoading, setError);

    return(
        <div className="admin-page-container">
            <div className="admin-page-title-container">
                <p className="admin-page-title">Sorteios</p>
                { loading && !error ? <DefaultLoader /> : false }
                { !loading && error ? <p className="admin-page-error-fetching">Ocorreu um erro, por favor recarregue a página</p> : false }
            </div>
            <div className="admin-draws-tables-container">
                <div className="admin-draw-container">
                    <div className="admin-draws-table-title-container">
                        <p className="admin-draw-table-title">Sorteios semanais</p>
                        {
                            !drawsWeek.filter((draw) => draw.editable).length ?
                            <img src={addrow} alt="add-row" className="add-row-icon" onClick={() => addDraw(drawsWeek, setDrawsWeek, 'semanal', setAddingWeek)} />
                            :
                            <img src={addrow} alt="add-row" className="add-row-icon-disabled" />
                        }
                        {
                            !addingWeek ?
                            <p></p>
                            :
                            <p className="admin-draw-table-warning">Salve o último sorteio adicionado para continuar</p>
                        }
                    </div>
                    <DrawTable draws={drawsWeek} setDraws={setDrawsWeek} adding={addingWeek} setAdding={setAddingWeek} />
                </div>
                <div className="admin-draw-container">
                    <div className="admin-draws-table-title-container">
                        <p className="admin-draw-table-title">Sorteios mensais</p>
                        {
                            !drawsMonth.filter((draw) => draw.editable).length ?
                            <img src={addrow} alt="add-row" className="add-row-icon" onClick={() => addDraw(drawsMonth, setDrawsMonth, 'Mensal', setAddingMonth)} />
                            :
                            <img src={addrow} alt="add-row" className="add-row-icon-disabled" />
                        }
                        {
                            !addingMonth ?
                            <p></p>
                            :
                            <p className="admin-draw-table-warning">Salve o último sorteio adicionado para continuar</p>
                        }
                    </div>
                    <DrawTable draws={drawsMonth} setDraws={setDrawsMonth} adding={addingMonth} setAdding={setAddingMonth} />
                </div>
                <div className="admin-draw-container">
                    <div className="admin-draws-table-title-container">
                        <p className="admin-draw-table-title">Sorteios finais</p>
                        {
                            !drawsFinal.filter((draw) => draw.editable).length ?
                            <img src={addrow} alt="add-row" className="add-row-icon" onClick={() => addDraw(drawsFinal, setDrawsFinal, 'Final', setAddingFinal)} />
                            :
                            <img src={addrow} alt="add-row" className="add-row-icon-disabled" />
                        }
                        {
                            !addingFinal ?
                            <p></p>
                            :
                            <p className="admin-draw-table-warning">Salve o último sorteio adicionado para continuar</p>
                        }
                    </div>
                    <DrawTable draws={drawsFinal} setDraws={setDrawsFinal} adding={addingFinal} setAdding={setAddingFinal} />
                </div>
            </div>
        </div>
    )
}

export default DrawsAdmin;