import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchResults = (sorteioId) => {

    const [results, setResults] = useState([]);

    useEffect(() => {
        if (!sorteioId) {
            return;
        }
        axios
            .get(`${process.env.REACT_APP_API_URL}/resultados?sorteio_id=${sorteioId}`)
            .then((response) => {
                setResults(response.data.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [sorteioId]);

    return results;
}

export default useFetchResults;