import React from 'react';

import './styles.css';

function TextInput({
  label, placeholder, value, setValue, warningMessage, disabled, rubik, black, type, inputRef, readOnly
}) {
  return (
    <div className={`text-input-component ${rubik ? 'rubik': ''}`} ref={inputRef}>
      <p className="label" style={{ color: black ? '#202020' : '#FFFFFF', fontSize: rubik ? '16px' : '18px' }}>{label}</p>
      <input
        className={`input ${warningMessage && !black ? 'invalid' : ''} ${!warningMessage && value && !disabled ? 'valid' : ''} ${disabled ? 'disabled' : ''} ${warningMessage && black ? 'invalid-black' : ''}`}
        value={value}
        type={type}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        style={{ fontSize: rubik ? '18px' : '21px', padding: '0px 20px' }}
      />
      <p className="warning-message" style={{ color: black ? '#FF5F5F' : '#FBE1E0' }}>{warningMessage}</p>
    </div>
  );
}

export default TextInput;