import React, { useEffect, useState, useContext, useRef } from "react";

import Popup from "../../components/Home/Popup/Popup";
import PopupSuccess from "../../components/Home/PopupSuccess/PopupSuccess";

import useFetchClientsResults from "../../hooks/useFetchClientsResults";
import useSetPageTitle from "../../hooks/useSetPageTitle";
import { Link, useSearchParams } from "react-router-dom";

import './styles.css';

import hero from '../../assets/solar_mais_premios/hero.png';
import premioSemanal from '../../assets/solar_mais_premios/premio_semanal.png';
import premioMensal from '../../assets/solar_mais_premios/premio_mensal.png';
import premioFinal from '../../assets/solar_mais_premios/premio_final.png';
import packProdutos from '../../assets/solar_mais_premios/pack_produtos.png';
import UserContext from "../../contexts/UserContext";
import axios from "axios";

function Home() {
    const { currentPromo, currentUser, setCurrentUser } = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const [popup, setPopup] = useState(null);
    const chancesSectionRef = useRef();
    const [clientId, setClientId] = useState(localStorage.getItem('clientId'));

    useEffect(() => {
        if (!popup) {
            document.body.classList.remove("overlay");
        } else {
            document.body.classList.add("overlay");
        }
    }, [popup])

    useEffect(() => {
        if (searchParams.has('success')) {
            setPopup('success')
        }

        let clientToken = searchParams.has('clientToken') ? searchParams.get('clientToken') : null;
        let clientId = searchParams.has('clientId') ? searchParams.get('clientId') : null;

        if (clientId?.includes('?')) {
            const [id, token] = clientId.split('?');
            clientId = id;
            if (token?.length && token.startsWith('clientToken')) {
                clientToken = token.split('=')[1];
            }
        }

        if (clientToken) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/client/me`, {
                    headers: {
                        'Authorization': `Bearer ${clientToken}`
                    },
                })
                .then((response) => {
                    setCurrentUser(response.data.client, clientToken)
                })
                .catch((err) => {
                    console.log(err);
                })
        } else if(clientId) {
            setCurrentUser(null, null)
            localStorage.setItem('clientId', clientId);
            setClientId(clientId)
        }
    }, [searchParams, setCurrentUser]);

    useSetPageTitle(`${currentPromo?.label} | Home`);
    const { pendingPrizes } = useFetchClientsResults();
    const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'auto' });

    useEffect(() => {
        if (popup) {
            scrollToTop();
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [popup]);

    return(
        <div className="home-background">
            {!currentUser &&
                <>
                <div className="home-hero">
                    <img src={hero} alt="Promoção Solar+ Prêmios"></img>
                    <Link className="cta" to={`signup?clientId=${clientId}`}>Cadastre-se agora!</Link>
                </div>
            
                <div className="home-how-to">
                    <h1 className="title text-yellow">COMO PARTICIPAR <br /> DA SOLAR+ PRÊMIOS</h1>
                    <div className="home-how-to-card">
                        <span className="home-how-to-card-number chat-bubble title text-yellow">1</span>
                        <span className="text"><strong>Acesse o seu aplicativo <br /></strong>Solar+<strong> e se cadastre</strong></span>
                    </div>

                    <div className="home-how-to-card">
                        <span className="home-how-to-card-number chat-bubble title text-yellow">2</span>
                        <span className="text"><strong>Compre no Solar+:</strong><br />Todos os pedidos no <br />Solar+ geram pelo menos <br />1 número da sorte</span>
                    </div>

                    <div className="home-how-to-card">
                        <span className="home-how-to-card-number chat-bubble title text-yellow">3</span>
                        <span className="text"><strong>Confira os sorteios:</strong><br />acompanhe no app e siga <br />nossas redes sociais para <br />ficar por dentro</span>
                    </div>
                </div>
            </>
            }
            {!!currentUser &&
                <div className="home-how-to">
                    <h1 className="title text-yellow">COMPRE AGORA E <br/>GANHE NÚMEROS DA SORTE!</h1>
                    <div className="home-how-to-card">
                        <span className="text big-text">Você já está <strong>cadastrado</strong> na promoção! <br /><br />Agora, <strong>compre no Solar+</strong> e comece a acumular <strong>números da sorte</strong> para concorrer aos prêmios!</span>
                    </div>
                </div>
            }
            <div className="home-prizes home-section">
                <h1 className="title text-white">QUAIS SÃO <br />OS PRÊMIOS DA <br />PROMOÇÃO?</h1>
                <div className="image-card">
                    <img className="white-border" src={premioSemanal} alt="Prêmio semanal"></img>
                    <h1 className="title text-white">PRÊMIO SEMANAL</h1>
                    <span className="text">Toda semana, você tem a <strong>chance <br />de ganhar baldes, coolers e <br />letreiros luminosos Coca-Cola.</strong></span>
                </div>
                <div className="image-card">
                    <img className="white-border" src={premioMensal} alt="Prêmio mensal"></img>
                    <h1 className="title text-white">PRÊMIO MENSAL</h1>
                    <span className="text">Todo mês, você tem a <strong>chance de <br />ganhar</strong> um <strong>Samsung Galaxy S22</strong></span>
                </div>
            </div>

            <div className="home-final-prize home-section ">
                <h1 className="title text-white">PRÊMIO FINAL</h1>
                <img src={premioFinal} alt="Prêmio final"></img>
                <div className="home-final-prize-card">
                    <span className="text text-white">Concorra a <strong>Motos Zero KM <br />Honda Cargo</strong> no final da <br />Promoção Solar+ Prêmios</span>
                    <h1 className="title text-white">NÃO PERCA TEMPO!</h1>
                </div>
            </div>

            <div className="home-chances home-section " ref={chancesSectionRef}>
                <h1 className="title text-yellow">AUMENTE SUAS <br />CHANCES</h1>
                <span className="text text-white">Compre <strong>produtos aceleradores </strong><br />e <strong>ganhe 2 números da sorte</strong>!<br /><br /> Na compra de um ou mais <br />produtos aceleradores, seu pedido <br />passa a gerar dois números da sorte. <br /><strong>Esta oferta não é acumulativa.</strong></span>
                <img src={packProdutos} alt="Produtos aceleradores"></img>
            </div>

            {popup === 'prizes' && pendingPrizes ? <Popup setPopup={setPopup} /> : false}
            {popup === 'success' ? <PopupSuccess setPopup={setPopup} /> : false}
        </div>
    )
}

export default Home;